















































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { WorkOrderFormEntityModel, WorkOrderFormQueryModel } from '@/entity-model/work-order-form-entity';
import WorkOrderFormService from '@/service/work-order-form';

@Component
export default class WorkOrderFormListComponent extends TableDialogFormComponent<WorkOrderFormEntityModel, WorkOrderFormQueryModel> {
    WorkOrderFormEntityModel = WorkOrderFormEntityModel;

    created() {
        this.initTable({
            service: WorkOrderFormService,
            queryModel: new WorkOrderFormQueryModel(),
            tableColumns: WorkOrderFormEntityModel.getTableColumns()
        });

        this.getList();
    }

    designClick(record) {
        this.$router.push(`/dashboard/work-order-form/${record.id}`);
    }

    deployClick(record) {
        (this.service as any).deploy(record.id).then(res => {
            this.showMessageSuccess('同步成功');

            this.getList();
        });
    }
}
